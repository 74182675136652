import React from 'react';
import { graphql } from 'gatsby';
import Loadable from 'react-loadable';

import Layout from '@components/Layout';
import Whitespace from '@components/Whitespace';
import * as PAGES from '@constants/pages';
// import Hero from '../components/landing/Hero';

import { container } from './index.module.scss';

export default function IndexPage({ location, data: { site } }) {
  const LazyLanding = Loadable.Map({
    loader: site.siteMetadata.lazyLanding.reduce((acc, item) => {
      acc[item] = () => import(`../components/landing/${item}`);
      return acc;
    }, {}),
    loading: () => <Whitespace />,
    render: (loaded, props) =>
      site.siteMetadata.lazyLanding.map((item) => {
        const Item = loaded[item].default;
        return <Item key={item} {...props} />;
      }),
  });

  return (
    <Layout location={location} page={PAGES.LANDING}>
      {() => (
        <div className={container}>
          <LazyLanding />
        </div>
      )}
    </Layout>
  );
}

export const query = graphql`
  query Landing {
    site {
      siteMetadata {
        lazyLanding
      }
    }
  }
`;
